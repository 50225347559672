<template lang="html">
    <div class="SiteFooter">
        <div class="">
            <span class="Hint">Copyright © TaxiFusion {{ currentYear }}</span>
        </div>
        <!-- <div class="">
            <a href="#">FAQ</a>
            <a href="#">Datenschutzerklärung</a>
            <a href="#">Impressum</a>
        </div> -->
    </div>
</template>
<script>
export default {
    computed: {
        currentYear() {
            return new Date().getFullYear();
        },
    },
};
</script>
<style lang="scss">
.SiteFooter {
    @extend %contentWrapper;
    color: var(--color-text-gray);
    display: flex;
    justify-content: space-between;
    padding: 4px;
    font-size: 0.75rem;
    grid-column-start: 2;
    grid-row-start: 2;
    grid-area: sitefooter;
    border-top: solid 1px color(text-inactive);
    width: calc(100% - 20px);
    margin: 0;

    @media screen and (max-width: breakpoint(tabletPortrait)) {
        padding-bottom: 0px;
        display: none;
    }

    svg {
        width: 0.875rem;
        height: 0.875rem;
        margin-left: 5px;
    }

    a {
        transition: all 0.3s;
        color: var(--color-text-gray);
        margin-right: 15px;

        &:hover {
            color: var(--color-text-gray-dark);
        }
    }

    > div {
        display: flex;
        align-items: center;
        flex-direction: row;
    }
}
</style>
