var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "AppNavigation",
    attrs: {
      "id": "AppNavigation"
    }
  }, [_c('nav', {
    staticClass: "Wrapper"
  }, [_c('router-link', {
    staticClass: "Logo",
    attrs: {
      "to": "/"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/svg/logo.svg"),
      "alt": ""
    }
  })]), _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/dashboard"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_vm._v("Dashboard")]), _vm.hasPerformanceAnalyticsModule ? _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/analytics"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_vm._v("Analyse")]) : _vm._e(), _vm.hasDriverLogsModule ? _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/driversLog"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_vm._v("Fahrtenbuch")]) : _vm._e(), _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/revenue"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_vm._v("Umsatz")]), _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/working-time"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_vm._v("Arbeitszeiten")]), _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/shifts"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_vm._v("Schichten")]), _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/trips"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_vm._v("Einzelfahrten")]), _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/operationride"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_vm._v("Betriebsnachweise")]), _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/employees"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_vm._v("Mitarbeiter")]), _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/cars"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_vm._v("Fahrzeuge")]), _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/invoice"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_vm._v("Rechnungen")]), _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/settings"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.setIndicatorPos.apply(null, arguments);
      }
    }
  }, [_vm._v("Einstellungen")]), _c('router-link', {
    staticClass: "Nav-Link",
    attrs: {
      "to": "/"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.handleLogoutClick.apply(null, arguments);
      }
    }
  }, [_c('span', [_vm._v("Logout")])]), _c('div', {
    staticClass: "BusinessName"
  }, [_c('span', [_vm._v("Unternehmen")]), _c('br'), _c('p', [_vm._v(_vm._s(_vm.businessName))])]), _c('span', {
    ref: "indicator",
    staticClass: "Indicator"
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }