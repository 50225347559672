<template>
    <div class="AppNavigation" id="AppNavigation">
        <nav class="Wrapper">
            <router-link to="/" class="Logo" @click.native="setIndicatorPos">
                <img src="@/assets/svg/logo.svg" alt />
            </router-link>

            <router-link class="Nav-Link" to="/dashboard" @click.native="setIndicatorPos"
                >Dashboard</router-link
            >
            <router-link
                v-if="hasPerformanceAnalyticsModule"
                class="Nav-Link"
                to="/analytics"
                @click.native="setIndicatorPos"
                >Analyse</router-link
            >
            <!-- <router-link
                v-if="hasReminderModule"
                class="Nav-Link"
                to="/schedular"
                @click.native="setIndicatorPos"
                >Schichtplanner</router-link
            > -->
            <router-link
                v-if="hasDriverLogsModule"
                class="Nav-Link"
                to="/driversLog"
                @click.native="setIndicatorPos"
                >Fahrtenbuch</router-link
            >
            <router-link class="Nav-Link" to="/revenue" @click.native="setIndicatorPos"
                >Umsatz</router-link
            >
            <router-link class="Nav-Link" to="/working-time" @click.native="setIndicatorPos"
                >Arbeitszeiten</router-link
            >
            <router-link class="Nav-Link" to="/shifts" @click.native="setIndicatorPos"
                >Schichten</router-link
            >
            <router-link class="Nav-Link" to="/trips" @click.native="setIndicatorPos"
                >Einzelfahrten</router-link
            >
            <router-link class="Nav-Link" to="/operationride" @click.native="setIndicatorPos"
                >Betriebsnachweise</router-link
            >
            <router-link class="Nav-Link" to="/employees" @click.native="setIndicatorPos"
                >Mitarbeiter</router-link
            >
            <router-link class="Nav-Link" to="/cars" @click.native="setIndicatorPos"
                >Fahrzeuge</router-link
            >
            <!-- <router-link
                v-if="hasReminderModule"
                class="Nav-Link"
                to="/savings-calculator"
                @click.native="setIndicatorPos"
                >Rücklagenrechner</router-link
            > -->

            <router-link class="Nav-Link" to="/invoice" @click.native="setIndicatorPos"
                >Rechnungen</router-link
            >
            <router-link class="Nav-Link" to="/settings" @click.native="setIndicatorPos"
                >Einstellungen</router-link
            >
            <router-link class="Nav-Link" to="/" @click.native="handleLogoutClick">
                <span>Logout</span>
            </router-link>
            <div class="BusinessName">
                <span>Unternehmen</span>
                <br />
                <p>{{ businessName }}</p>
            </div>
            <span class="Indicator" ref="indicator"></span>
        </nav>
    </div>
</template>
<script type="text/javascript">
import { mapGetters } from 'vuex';
import anime from 'animejs';
import axios from 'axios';
export default {
    name: 'AppNavigation',
    watch: {
        immediate: true,
        $route: {
            handler() {
                this.$nextTick(this.setIndicatorPos);
            },
        },
    },
    watch: {
        $route: {
            handler() {
                this.$nextTick(this.setIndicatorPos);
            },
        },
    },
    computed: {
        ...mapGetters(['businessName', 'modules']),
        hasPerformanceAnalyticsModule() {
            return this.modules.includes('performanceAnalysis');
        },
        hasDriverLogsModule() {
            return this.modules.includes('driverLogs');
        },
        hasReminderModule() {
            return this.modules.includes('reminder');
        },
    },
    methods: {
        async handleLogoutClick(e) {
            e.preventDefault();

            sessionStorage.clear();
            localStorage.clear();

            await axios.get(`${process.env.VUE_APP_API_BASE_URL}/logout`, {
                withCredentials: true,
            });
            this.$router.push('/');
        },
        setIndicatorPos() {
            const route = this.$route.path;
            const link = this.$el.querySelector(`a[href="${route}"]`);
            if (!link) return;
            const padding = 8;
            const offsetTop = 100;
            const indicator = this.$refs.indicator;
            const indicatorOldY = indicator?.getBoundingClientRect?.()?.y || offsetTop;
            const indicatorNewY = Math.max(link.offsetTop + padding, offsetTop);
            const diff = Math.abs(indicatorOldY - indicatorNewY);
            // increase scale if the distance is bigger
            const scaleMax = Math.max(1.5, 1 + diff / 100);
            const direction = indicatorOldY > indicatorNewY ? 'up' : 'down';

            requestAnimationFrame(() => {
                anime({
                    targets: indicator,
                    translateY: [indicatorOldY, indicatorNewY],
                    translateZ: 0,
                    easing: 'spring(1, 80, 13, 0)',
                    scaleY: [
                        { value: scaleMax, delay: 70, duration: 200, easing: 'easeOutQuad' },
                        { value: 1, delay: 0, duration: 200, easing: 'easeOutQuad' },
                    ],
                    transformOrigin: direction === 'up' ? 'top center' : 'bottom center',
                });
            });
        },
    },
    mounted() {
        setTimeout(() => {
            this.$nextTick(this.setIndicatorPos);
        }, 1000);
    },
};
</script>
<style lang="scss">
.theme-dark {
    .AppNavigation {
        background: #000;

        .BusinessName {
            color: #fff;
        }
    }
}
.AppNavigation {
    position: sticky;
    width: 100%;
    background: linear-gradient(-42deg, color(blue-light) 0%, color(blue-dark) 100%);
    background: var(--color-blue-darker);
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
    height: 100%;
    grid-area: sidebar;
    width: var(--sidebarWidth);
    overflow: hidden;
    z-index: zIndex(menu);
    top: 0;
    left: 0;
    transition: width 0.4s ease(out-quad);

    @media (max-width: breakpoint(tabletPortrait)) {
        position: fixed;
        bottom: 0;
        left: 0;
        top: unset;
        height: 85px;
        width: 100vw;
        padding-bottom: env(safe-area-inset-bottom);
    }

    .BusinessName {
        color: var(--color-white);
        font-size: 0.75rem;
        margin-top: auto;
        justify-self: flex-end;
        margin-bottom: 20px;
        line-height: 1.5;
        padding: 0 0 10px;

        span {
            font-size: 0.6rem;
        }
        p {
            font-weight: 700;
        }
        @media (max-width: breakpoint(tabletPortrait)) {
            display: none;
        }
    }

    .Logo {
        width: 60px;
        margin: 0 0 20px;
        padding-top: 0;

        img {
            width: 100%;
        }

        @media (max-width: breakpoint(tabletPortrait)) {
            display: none;
        }
    }
    ::-webkit-scrollbar {
        display: none;
    }
    .Wrapper {
        display: flex;
        justify-content: space-between;
        position: relative;
        flex-direction: column;
        padding: 20px 30px 0 30px;
        height: 100%;

        @media screen and (max-width: breakpoint(tabletPortrait)) {
            flex-direction: row;
            column-gap: 20px;
            padding: 10px 20px;
            overflow: auto;
        }
    }

    .Indicator {
        position: absolute;
        top: 0;
        left: 0;
        background: color(bg);
        transform: translateY(134px);
        height: 24px;
        width: 10px;
        will-change: auto;
        transform-origin: center center;

        @media screen and (max-width: breakpoint(tabletPortrait)) {
            display: none;
        }
    }

    a {
        font-size: 0.75rem;
        padding: 8px 0 8px;
        display: inline-block;
        color: rgb(159, 159, 159);
        font-weight: 700;
        transition: all 0.3s;
        text-transform: uppercase;

        &:hover,
        &.router-link-exact-active {
            color: rgba(255, 255, 255, 1);
        }

        &.CTA {
            padding: 15px 0;

            span {
                display: block;
                padding: 5px 18px 3px;
                background-color: color(yellow-dark);
                color: var(--color-text-gray-dark);
            }
        }
    }
}
</style>
